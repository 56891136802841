/*Agregado*/

/*Estilos adicionados*/  
/* TopbarUser */  //ok
.img-user-profile{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    @media only screen and (max-width: 575px) 
    {
        width: 45px;
        height: 45px;
    }
}
.isoImgWrapper {
    width: 71px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg{
        margin-left:5px;
        polygon{
        }
    }
}
span.userText{
    margin-right:25px;
    font-size: small;
    @media only screen and (max-width: 575px) 
    {
        margin-right: 10px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        margin-right: 10px;
    }
}
.logo-sidebar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-menu{
    margin-right: 25px;
    a {
        margin: 0px 20px;
    }
    .ant-divider-vertical{
        border-width: thin;
        height: 25px;
        border-color: rgba(255,255,255,0.5);
    }
    @media only screen and (max-width: 575px)
    {
        display: none;
    }
}
.menu-mobile{
    display: none;
    @media only screen and (max-width: 575px)
    {
        display: block;
    }
    a span {
        .anticon.anticon-unordered-list{
            margin: 0px;
        }
        .anticon.anticon-play-square{
            margin: 0px;
        }
        .nav-text{
            margin: 0px;
        }
    }
}

.ant-popover-arrow{
  display: none !important;
}
/* /TopbarUser */

/* Notificacion */
.ant-notification.ant-notification-bottomLeft{
    margin-left: 0px;
    width: inherit;
    max-width: 1000px;
    .content-notification{
        margin-bottom: 0px;
        margin-top: 12px;
        font-size: 18px;
        padding:25px 25px;
        @media only screen and (max-width: 575px) 
        {
            font-size: 15px;
            padding:16px 16px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 15px;
            padding:18px 18px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 16px;
            padding:20px 20px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 17px;
            padding:23px 23px;
        }
        .ant-notification-notice-content{
            & > div{
                display: flex;
                flex-wrap: nowrap;
                .ant-notification-notice-message{
                    display: none;
                }
                .ant-notification-notice-description{
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 23px;
                    flex:10;
                    p{
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 23px;
                        span{
                            font-weight: 600;
                        }
                        @media only screen and (max-width: 575px) 
                        {
                            font-size: 14px;
                            line-height: 17px;
                        }
                        @media only screen and (min-width: 576px) and (max-width: 767px) 
                        {
                            font-size: 15px;
                            line-height: 18px;
                        }
                        @media only screen and (min-width: 768px) and (max-width: 991px) 
                        {
                            font-size: 16px;
                            line-height: 19px;
                        }
                        @media only screen and (min-width: 992px) and (max-width: 1199px) 
                        {
                            font-size: 17px;
                            line-height: 21px;
                        }
                    }
                    @media only screen and (max-width: 575px) 
                    {
                        font-size: 14px;
                        line-height: 17px;
                    }
                    @media only screen and (min-width: 576px) and (max-width: 767px) 
                    {
                        font-size: 15px;
                        line-height: 18px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 991px) 
                    {
                        font-size: 16px;
                        line-height: 19px;
                    }
                    @media only screen and (min-width: 992px) and (max-width: 1199px) 
                    {
                        font-size: 17px;
                        line-height: 21px;
                    }
                }
                .ant-notification-notice-btn {
                    margin-top: 0px;
                    flex: 4;
                    text-align: center;
                    .content-button-main{
                        padding-top:10px;
                        display: inline-block;
                        .button-main{
                            font-weight: 600;
                            padding:5px 40px 4.5px 40px;
                            @media only screen and (max-width: 575px) 
                            {
                                padding:3.5px 26px 3px 26px;
                            }
                            @media only screen and (min-width: 576px) and (max-width: 767px) 
                            {
                                padding:3.5px 28px 3px 28px;
                            }
                            @media only screen and (min-width: 768px) and (max-width: 991px) 
                            {
                                padding:4px 30px 3.5px 30px;
                            }
                            @media only screen and (min-width: 992px) and (max-width: 1199px) 
                            {
                                padding:5px 35px 4.5px 35px;
                            }
                        }
                    }
                }
            }
        }   
    }

    .ant-notification-notice-close{
        top:5px;
        right:5px;
    }
    .ant-notification-close-icon{
        font-size: 22px;
        @media only screen and (max-width: 575px) 
        {
            font-size: 16px;
            line-height: 20px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 17px;
            line-height: 21px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 18px;
            line-height: 22px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 20px;
            line-height: 23px;
        }
    }
}
/* Notificacion*/

/* Metas personales */
.title-col-tasks{
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;
    text-align: center;
    padding:10.5px 11px;
    margin: 0px;
    font-size: 14.5px;
    @media only screen and (max-width: 575px) 
    {
        font-size: 13.5px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        font-size: 13.5px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        font-size: 13px;
        padding:10.5px 7px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        font-size: 12px;
        padding:10.5px 7px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) 
    {
        font-size: 12px;
        padding:10.5px 7px;
    }
}

.row-tasks{
    margin-bottom: 8px;
    display: flex;
    flex-wrap: nowrap;
    &:last-child{
        margin-bottom: 0px;
    }
    .col-tasks{
        flex:1;
        margin-left: 3px;
        margin-right: 3px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
        }
        @media only screen and (max-width: 575px) 
        {
            flex:inherit;
            min-width: 225px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            flex:inherit;
            min-width: 215px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            flex:inherit;
            min-width: 215px;
        }
    }
    @media only screen and (max-width: 575px) 
    {
        width: 200%;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        width: 200%;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        width: 100%;
    }
}

.item-tasks{
    padding:9px 11px;
    height: 100%;
    line-height: 19px;
    font-weight: 500;
    white-space: break-spaces;
    &:last-child{
        margin-bottom: 0px;
    }
    p{
        line-height: 19px;
        font-weight: 500;
        &:last-child{
            margin-bottom: 0px;
        }
    }
    .icons-item-task-first{
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        span{
            display: inline-block;
            font-weight: 500;
            line-height: 19px;
        }
        svg{
            .cls-2
            {
            }
            .cls-1{
                opacity: 1;
            }
        }
    }
    div textarea {
        min-height: 200px;
    }
}
.item-tasks-icon {
    text-align: right;
    svg{
        .cls-2
        {
        }
        .cls-1{
            opacity: 1;
        }
    }
}
.item-tasks.item-task-first{
    min-height: 240px;
    .element-edit{
        height: 80%;
        div {
            height: 100%;
        }
        textarea {
            height: 100%;
        }
    }
}

.scroll-touch {
    @media only screen and (max-width: 575px) 
    {
        -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
        overflow: auto;
        width: 100%;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
        overflow: auto;
        width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
        overflow: auto;
        width: 100%;
    }
}
/* /Metas personales*/

/* Visión Misión Metas*/
.data-pillars{
    display: flex;
    flex-wrap: nowrap;
    .img-data-pillars{
        flex:2;
        margin-right: 0.6%;
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 22px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            margin-bottom: 22px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            flex:4;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            flex:3;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) 
        {
            flex:4;
        }
    }
    .info-data-pillars{
        flex:5;
        margin-left: 0.6%;
        .content-blue{
            white-space: break-spaces;
            .element-edit{
                height: 100%;
                & > div{
                    height: 100%;
                    textarea{
                        height: 100%;
                    }
                }
            }
        }
        @media only screen and (max-width: 575px) 
        {
            .content-blue{
                margin-bottom: 0px;
            }
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            .content-blue{
                margin-bottom: 0px;
            }
        }   
    }
    @media only screen and (max-width: 575px) 
    {
        display: block;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        display: block;
    }
}

.img-data-pillars{
    .content-blue{
        margin-bottom: 0px;
        padding:27px 22px 0px 12px;
        img{
            max-width: 100%;
            height: auto;
            @media only screen and (max-width: 575px) 
            {
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                width: 100%;
            }
        }
    }
}

.info-data-pillars{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    .content-blue{
        padding:15px 15px;
        margin-bottom: 0px;
        height: 140px;
        h4{
            font-weight: 500;
            text-transform: uppercase;
        }
        @media only screen and (max-width: 575px) 
        {
            height: 130px;     
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            height: 130px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            height: 120px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            height: 120px;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) 
        {
            height: 145px;
        }
    }
}
/* /Visión Misión Metas*/

/* Cargas y Herramientas */
.data-tools{
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    .columna-data-tools{
        flex:1;
        &:first-child{
            margin-right: 0.7%;
            @media only screen and (max-width: 575px) 
            {
                margin-right: 0px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-right: 0px;
            }
        }
        &:last-child{
            margin-left: 0.7%;
            @media only screen and (max-width: 575px) 
            {
                margin-left: 0px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-left: 0px;
            }
        }
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 20px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            margin-bottom: 20px;
        }
        .content-blue{
            height: 600px;
            @media only screen and (max-width: 575px) 
            {
                height: 300px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                height: 400px;
            }
        }
    }
    .content-blue{
        flex:1;
        &:first-child{
            margin-right: 0.7%;
            @media only screen and (max-width: 575px) 
            {
                margin-right: 0px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-right: 0px;
            }
        }
        &:last-child{
            margin-left: 0.7%;
            @media only screen and (max-width: 575px) 
            {
                margin-left: 0px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-left: 0px;
            }
        }
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 0px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            margin-bottom: 0px;
        }
    }
    .content-problem {
        min-height: 300px;
        .ql-toolbar, .ql-container{
        }
        .ck-editor__editable{
            min-height: 200px;
        }
    }
    .resumen-problem
    {
        min-height: 140px;
    }
    .content-files 
    {
        height: auto;
        @media only screen and (max-width: 575px) 
        {
            height: auto;    
        }
    }
    .comment-diagram {
        min-height: 100px;
    }
    .solution-link-items {
        min-height: 60px;
        textarea, div {
            height: unset;
        }
    }

    .link-icons-svg
    {
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        & > div{
            margin-left: 3px;
            display: flex;
            align-items: center;
        }
        .cls-1
        {
            opacity: 1;
        }
        .cls-2
        {
        }
    }

    @media only screen and (max-width: 575px) 
    {
        display: block;
        margin-bottom: 0px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        display: block;
        margin-bottom: 0px;
    }
}
/* /Cargas y Herramientas */

/* FODA */
.data-FODA{
    .content-blue{
        height: 270px;
        margin-bottom: 20px;
        .element-edit{
            height: 100%;
            & > div{
                height: 100%;
                textarea{
                    height: 100%;
                }
            }
        }
    }
}
.background-transparent{
}
/* /FODA */

/* Modal */
.modal-init.ant-modal-confirm{
    .ant-modal-body{
        padding:45px;
        text-align: center;
        @media only screen and (max-width: 575px) 
        {
        padding:30px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
        padding:35px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
        padding:38px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
        padding:40px;
        }
    }
    .ant-modal-content{
        p{
            margin-bottom: 30px;
            @media only screen and (max-width: 575px) 
            {
                margin-bottom:22px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-bottom:24px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                margin-bottom:26px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                margin-bottom:28px;
            }
        }
    }

    .ant-modal-confirm-body .ant-modal-confirm-title{
        font-size: 26px;
        font-weight: 600;
        line-height: 31px;
        max-width: 400px;
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        &::after{
            content:' ';
            width: 130px;
            height: 3px;
            display: inline-block;
            @media only screen and (max-width: 575px) 
            {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
            }
        }
        @media only screen and (max-width: 575px) 
        {
        font-size: 22px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
        font-size: 23px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
        font-size: 24px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
        font-size: 25px;
        }
    }

    .ant-modal-confirm-body .ant-modal-confirm-content{
        font-size: 18px;
        line-height:24px;
        @media only screen and (max-width: 575px) 
        {
        font-size: 16px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
        font-size: 16.5px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
        font-size: 17px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
        font-size: 17.5px;
        }
    }

    .ant-modal-confirm-body > .anticon{
        display: none;
    }

    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
        margin-left: 0px;
    }

    .ant-modal-confirm-btns{
        text-align: center;
        float:none;
    }

    .ant-btn-button-modal{
        background-color: transparent;
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 600;
        padding: 15px 32px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        span
        {
            line-height: 1px;
        }
        @media only screen and (max-width: 575px) 
        {
        font-size: 14px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
        font-size: 14.5px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
        font-size: 15px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
        font-size: 15.5px;
        }
    }
}
/* /Modal */

/* Editor de texto */   /*ok*/
div.ck-editor__editable {
    min-height: 150px;
    h1, h2, h3, h4 {  
    }
    p{
        margin-bottom: 15px;
    }
}
.ck-upload-complete-icon-show {
    min-height: unset;
}
.ck-image-upload-complete-icon {
    height: 20px !important;
}
.ck-content .image>figcaption {
    min-height: unset;
}
.ck-content figure.image{
    text-align: unset;
}
.ck-content figure.image.image-style-align-center{
    margin: 1em auto;
}
.ck-content figure.image{
    margin-left: unset;
    margin-right: unset;
}
:root {
	--ck-image-style-spacing: 1.5em;
}
.image-style-align-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}.image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}.image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
div.ck div.ck-widget__type-around__button.ck-widget__type-around__button_after, 
div.ck div.ck-widget__type-around__button.ck-widget__type-around__button_before {
  height: 20px;
}

/* /Editor de texto */

/* Iconos SAVE y EDIT*/  /*ok*/
.content-title-edit
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    h4{
        margin-bottom: 0px;
    }
    h3{
        margin-bottom: 0px;
    }
    .link-save-edit{
        align-items: center;
    }
}
.content-save-edit
{
    padding-top: 10px;
    text-align: right;
}
.content-save-edit-textarea 
{
    padding-top: 0px;
}
.link-save-edit
{
    display: flex;
    align-items: flex-end;
    a{
        font-size: 13px;
        display: flex;
        align-items: center;
        div{
            display: flex;
            align-items: center;
            .cls-2
            {
            }
            .a{
            }
            .cls-1
            {
                opacity: 1;
            }
        }
        &:hover,&:active,&:focus
        {
        }
    }
}
.link-save-edit.save-edit
{
    display: inline-block;
    text-align: right;
}
/* / Iconos SAVE y EDIT*/

/* SELECCIONAR ARCHIVOS INPUT FILE */ /*ok*/
.ant-upload-list-picture-card .ant-upload-list-item{
    width: 120px !important;
    height: 120px !important;
    margin: 0px;
}
.ant-upload-list-picture-card-container{
    width: 120px !important;
    height: 120px !important;
}
.ant-upload-select-picture-card span.ant-upload, div.ant-upload-select-text span.ant-upload{
    cursor: pointer;
    padding: 8px !important;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    border-radius: 30px;
    display: inline-block;
    &:hover,&:active,&:focus{
    }
    &:hover,&:active,&:focus{
        
    }
    @media only screen and (max-width: 575px) 
    {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) 
    {
        font-size: 13.5px;
        padding:1px 13px 1.5px 13px;
    }
}
.ant-upload.ant-upload-select-picture-card{
    height: auto !important;
    width: auto !important;
    min-width: 120px;
    background-color: transparent !important;
    border: none !important;
    .ant-upload{
    }
}
.ant-upload-list-item-name, div.ant-upload-list-item-info .anticon-paper-clip{
}
div.ant-upload-list-item:hover .ant-upload-list-item-info {
}
span.ant-upload-list-item-card-actions .anticon {
}
/*SELECCIONAR ARCHIVOS INPUT FILE */ /*ok*/

/* BADGE NUMBER */ /*ok*/
.badge-color{       
    .ant-badge-count{
    }
}
/* /BADGE NUMBER */ /*ok*/


/* Perfiles */
.header-profile{
    display: flex;
    flex-wrap: nowrap;
    padding-bottom:40px;
    margin-bottom: 35px;
    @media only screen and (max-width: 575px) 
    {
        padding-bottom:38px;
        margin-bottom: 25px;
        flex-wrap: wrap;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding-bottom:32px;
        margin-bottom: 28px;
        flex-wrap: wrap;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        padding-bottom:35px;
        margin-bottom: 30px;
        flex-wrap: wrap;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        padding-bottom:37px;
        margin-bottom: 32px;
    }
    .data-img-profile{
        flex:3;
        margin-right: 25px;
        @media only screen and (max-width: 575px) 
        {
            margin-right: 0px;
            width: 100%;
            flex:inherit;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            margin-right: 0px;
            width: 100%;
            flex:inherit;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            margin-right: 0px;
            width: 100%;
            flex:inherit;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            margin-right: 23px;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) 
        {
            margin-right: 2%;
        }
        .image-profile{
            width: 148px;
            height: 148px;
            position:relative;
            @media only screen and (max-width: 575px) 
            {
                width: 115px;
                height: 115px;
                margin-left:auto;
                margin-right: auto;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                width: 115px;
                height: 115px;
                margin-left:auto;
                margin-right: auto;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                width: 120px;
                height: 120px;
                margin-left:auto;
                margin-right: auto;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                width: 125px;
                height: 125px;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1599px) 
            {
                width: 125px;
                height: 125px;
            }
            
            img.img-profile{
                max-width: 100%;
                height: auto;
                border-radius: 50%;
                display: inline-block;
                width: 146px;
                height: 146px;
                @media only screen and (max-width: 575px) 
                {
                    width: 113px;
                    height: 113px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    width: 113px;
                    height: 113px;
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                    width: 118px;
                    height: 118px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                    width: 123px;
                    height: 123px;
                }
                @media only screen and (min-width: 1200px) and (max-width: 1599px) 
                {
                    width: 123px;
                    height: 123px;
                }
            }
            .a-edit-profile{
                position:absolute;
                right:0px;
                bottom:10px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    
    .profile-contact{
        flex:22;
        @media only screen and (max-width: 575px) 
        {
            text-align: center;
            flex: inherit;
            width: 100%;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            text-align: center;
            flex: inherit;
            width: 100%;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            flex: inherit;
            text-align: center;
            width: 100%;
        }
        p{
            margin-bottom: 15px;
        }

        .profile-social-media{
            padding:0px 0px 0px 5px;
            margin: 0px;
            display: flex;
            font-size: 18px;
            svg{
                path, polygon, circle{
                }
            }

            .li-icon-tag
            {
                display:flex;
                & > div{
                    margin-right:10px;
                }
            }

            .li-icon{
                div{
                    padding-top:3px;
                }
            }

            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 14px;
                text-align: left;
                flex-wrap: wrap;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 15px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 16px;
                text-align: center;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 16.5px;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1599px) 
            {
                font-size: 16px;
            }
            li{
                background-image: inherit;
                padding:0px;
                margin: 0px;
                margin-right: 40px;
                img{
                    margin-right: 8px;
                }
                a{
                    font-weight: 300;
                    word-break: break-word;
                }
                @media only screen and (max-width: 575px) 
                {
                    margin-right: 0px;
                    margin-bottom: 10px;
                    width: 50%;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    margin-right: 15px;
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                    margin-right: 30px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                    margin-right: 25px;
                    font-size: 16px;
                }
                @media only screen and (min-width: 1200px) and (max-width: 1599px) 
                {
                    margin-right: 12px;
                    font-size: 16px;
                }
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }

    .content-btn{
        flex:6;
        text-align: right;
        a{
            font-size: 0.9em;
        }
        @media only screen and (max-width: 575px) 
        {
            padding-top: 20px;
            text-align: center;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding-top: 20px;
            text-align: center;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding-top: 20px;
            text-align: center;
        }
    }
}

.header-profile-modified{
    margin-bottom: 0px;
}

.content-profile-aux
{
    width: calc(100% + 70px);
    transform: translateX(-35px);
    padding: 0px 35px;
    padding-top: 45px;
    @media only screen and (min-width: 1200px) and (max-width: 1599px) 
    {
        width: calc(100% + 60px);
        transform: translateX(-30px);
        padding: 0px 30px;
        padding-top: 45px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        width: calc(100% + 50px);
        transform: translateX(-25px);
        padding: 0px 25px;
        padding-top: 35px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        width: calc(100% + 36px);
        transform: translateX(-18px);
        padding: 0px 18px;
        padding-top: 33px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        width: calc(100% + 36px);
        transform: translateX(-18px);
        padding: 0px 18px;
        padding-top: 33px;
    }
    @media only screen and (max-width: 575px) 
    {
        width: calc(100% + 30px);
        transform: translateX(-15px);
        padding: 0px 15px;
        padding-top: 30px;
    }
    .content-title-edit h3
    {
    }
    .line-profile
    { 
    }
    .advanced-profile
    {
    }
}
button.button-perfil-return {
    position: absolute !important;
    right: 0px;
}
/* /Perfiles */

/* BG-COMPANY */    //ok
.bg-company{
    padding: 30px 25px 30px 25px;
    margin-bottom: 35px;
    
    @media only screen and (max-width: 575px) 
    {
        padding: 22px 17px 22px 17px;
        margin-bottom: 20px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding: 24px 19px 24px 19px;
        margin-bottom: 25px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        padding: 26px 21px 25px 21px;
        margin-bottom: 32px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        padding: 28px 23px 28px 23px;
        margin-bottom: 33px;
    }
    .title-edit-int-personales
    {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h3{
            font-size: 20px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 16px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 17px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 18px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 19px;
            }
        }
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 10px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            margin-bottom: 15px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            margin-bottom: 18px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            margin-bottom: 19px;
        }
    }
}
/* /BG-COMPANY */

/* componente INTERESESPERSONALES */    //ok
.bg-int-personales{
    padding: 30px 25px 30px 25px;
    margin-bottom: 35px;
    
    @media only screen and (max-width: 575px) 
    {
        padding: 22px 17px 22px 17px;
        margin-bottom: 20px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding: 24px 19px 24px 19px;
        margin-bottom: 25px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        padding: 26px 21px 25px 21px;
        margin-bottom: 32px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        padding: 28px 23px 28px 23px;
        margin-bottom: 33px;
    }

    .title-edit-int-personales
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        h3{
            font-size: 20px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 16px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 17px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 18px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 19px;
            }
        }
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 10px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            margin-bottom: 15px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            margin-bottom: 18px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            margin-bottom: 19px;
        }
    }
}
/* /componente INTERESESPERSONALES */

/* /componente EDITARINTERESESPERSONALES */     //ok
.bg-editor-perfil{
    padding: 30px 25px 30px 25px;
    margin-bottom: 35px;
    min-height:300px;
    white-space: break-spaces;
    p {
        white-space: break-spaces;
    }
    ul {
        white-space: initial;
    }
    & > div{
        textarea {
            height: 100% !important;
        }
    }
    @media only screen and (max-width: 575px) 
    {
        padding: 22px 17px 22px 17px;
        margin-bottom: 20px;
        min-height: auto;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding: 24px 19px 24px 19px;
        margin-bottom: 25px;
        min-height: auto;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        padding: 26px 21px 25px 21px;
        margin-bottom: 32px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        padding: 28px 23px 28px 23px;
        margin-bottom: 33px;
    }

    .ck.ck-editor
    {
        margin-bottom: 15px;
        .ck-editor__main{
            .ck-content{
                min-height: 160px;

            }
        }
    }

    .ql-editor, .ql-toolbar, .ql-container {
        height: unset;
    }
    .quill {
        margin-bottom: 15px;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}
/* /componente EDITARINTERESESPERSONALES */

/* Editar Formulario */     //ok
.content-form-editar
{
    .ant-form-horizontal .ant-form-item-control
    {
        min-width:auto; 
    }

    .ant-form-item
    {
        .ant-col-sm-offset-5{
            margin-left: 0px;
        }
        .ant-col-sm-14
        {
            width: 100%;
        }
        .ant-form-item-label > label
        {
            &::before
            {           
            }
        }
    }
    h3{
        margin-bottom: 0px;
        margin-top: 20px;
    }

    input[type="text"], input[type="email"], input[type="tel"], textarea{
        border-radius: 0px !important;
        box-shadow: none;
        outline: none;
        border:none;
        &:hover, &:active,&:focus{
            box-shadow: none;
            outline: none;
            border:none;
        }
    }

    textarea{
        resize: none;
        min-height: 150px !important;
    }

    .img-profile-message{
        display: flex;
        font-size: 16px;
        p{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 10px;
            }
        }
    }

    .ant-form
    {
        .form-align-perfil
        {
            .ant-form-item
            {
                .ant-form-item-label
                {
                    flex: 0 0 100%;
                    max-width: 100%;
                    text-align: left;
                }
            }
        }  
    }

    .react-pass-verify
    {
        height: 36px;
        .ReactPasswordStrength-input
        {
            height: 35px;
        }
        .ReactPasswordStrength-strength-desc
        {
            padding: 9px 12px;
            font-size: 16px;
            width: 35%;
            @media only screen and (max-width: 575px) 
            {
                font-size: 14px;
                padding: 9px 4px;
                width: 38%;
            }
        }
    }
}
/* /Editar Formulario */

/* MODULO */    //ok
.sessions-video
{
    padding-bottom: 30px;
    padding-left: 10%;
    h3{
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 575px) 
    {
        padding-left: 7%;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding-left: 8%;
    }
}

/* / MODULO */

/* UserModel */   //ok
.content-usermodel
{
    padding:15px 43px 35px 38px;
    @media only screen and (max-width: 575px) 
    {
        padding:11px 0px 0px 0px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding:12px 37px 0px 32px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        padding:13px 39px 0px 34px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        padding:14px 41px 0px 36px;
    }

    .ant-tabs-content, .diagram-content
    {
        padding-left: 15px;
        padding-right: 15px;
    }
    .diagram-content{
        padding-top: 15px;
        .data-tools .content-problem {
            min-height: 180px;
        }
    }
    .diagram-content .data-tools .content-problem .ck-editor__editable {
        min-height: 150px;
    }

    .ant-tabs, .diagram-content{  
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 20px;
        clear: both;
        
        @media only screen and (max-width: 575px) 
        {
            font-size: 14px;
            
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 14.5px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 15px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 15.5px;
        }
        p{
            margin-bottom: 16px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 14px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 14.5px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 15px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 15.5px;
            }
        }

        ul{
            margin-bottom: 15px;
            li{
                margin-bottom: 5px;
            }
        }

        h3{
            @media only screen and (max-width: 575px) 
            {
                margin-bottom: 13px !important;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-bottom: 15px !important;
            }
        }

        .data-tools .content-blue:last-child,.data-tools .content-blue:first-child
        {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .ant-tabs-top > .ant-tabs-nav
    {
        margin-bottom: 25px;
        &::before
        {
            border-bottom: none;
        }
    }

    .ant-tabs-nav-container{
        font-size: 26px;
        line-height: 31px;
        @media only screen and (max-width: 575px) 
        {
            font-size: 16px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 18px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 24px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 25px;
        }
    }

    .ant-tabs-nav .ant-tabs-tab{
        font-weight: 500;
        padding:13px 40px;
        font-size: 18px;
        margin:0px;
        @media only screen and (max-width: 575px) 
        {
            padding:15px 22px;
            font-size: 16px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding:16px 22px;
            font-size: 17px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding:18px 14px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding:19px 15px;
        }
    }

    .ant-tabs-nav .ant-tabs-tab-active{
        position: relative;
        
        &::after{
            content:' ';
            display: block;
            width: 80%;
            height: 5px;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            bottom:-3px;
            left:50%;
            z-index: 100;
            transform: translateX(-50%);
        }
        .ant-tabs-tab-btn{
            .tab-icons
            {
                div{
                    .injected-svg
                    {
                        @media only screen and (max-width: 575px) 
                        {
                            width: 14px !important;
                        }
                        @media only screen and (min-width: 576px) and (max-width: 767px) 
                        {
                            width: 15px !important;
                        }
                    }
                    
                    .cls-2, .a
                    {
                        
                    }
                }
            }
        }
    }

    .ant-tabs-tab-btn
    {
        &:active, &:focus
        {
            
        }
        .tab-icons
        {
            display: flex;
            div{
                display: flex;
                align-items: center;
                .cls-2, .a
                {
                }
                .cls-1
                {
                    opacity: 1;
                }
            }
            .ant-badge{
                right: -18px;
                @media only screen and (max-width: 575px) 
                {
                    right: -14px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    right: -15px;
                }
            }
        }
    }
    .ant-tabs-nav .ant-tabs-tab:hover{
        
    }
    .ant-tabs-bar
    {
        margin-bottom: 40px;
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 15px;
        }
    }

    .ant-tabs-ink-bar{
        display: none !important;
    }

    .ant-radio-wrapper
    {
        .ant-radio-inner
        {
        }
        .ant-radio-input:focus + .ant-radio-inner{
        }
        .ant-radio-checked .ant-radio-inner::after
        {
        }
    }
}
/* /UserModel */

/*Lectura*/   //ok
.listado-lectura
{
    padding: 13px 15px;
    .ant-list-items
    {
        padding:0px 0px;
        margin:0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media only screen and (max-width: 575px) 
        {
            display: block;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            display: block;
        }
        & > li.ant-list-item{
            background-image: inherit;
            padding:0px;
            width: 50%;
            margin-bottom: 25px;
            border-bottom: 0px;
            &::before{
                content:none;
            }
            @media only screen and (max-width: 575px) 
            {
                width: 100%;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                width: 100%;
            }
            .ant-avatar-image{
                border-radius: 0px;
                @media only screen and (max-width: 575px) 
                {
                    width: 24px !important;
                    height: auto !important;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                }
            }
            h4.ant-list-item-meta-title{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0px;
                
                line-height: 22px;
    
                @media only screen and (max-width: 575px) 
                {
                    font-size: 14px;
                    line-height: 18px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    font-size: 15px;
                    line-height: 19px;
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                    font-size: 16px;
                    line-height: 20px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                    font-size: 17px;
                    line-height: 21px;
                }
            }
            a{
                line-height: 19px;
            }
            .ant-list-item-meta-avatar{
                @media only screen and (max-width: 575px) 
                {
                    margin-right: 11px;
                }
            }
        }
    }
    .cls-1{
        
    }
    @media only screen and (max-width: 575px) 
    {
        padding: 13px 0px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding: 13px 0px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
    }
}
/* /Lectura*/

/*componente ACTIVIDADES*/  //ok
.item-proyectos
{
    .image-item-proyectos
    {
        position:relative;
        img{
            width: 100%;
            height: auto;
        }
        .category-item-proyectos{
            position:absolute;
            right:0px;
            top:0px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 28px;
            padding:0px 11px;
            a{
                font-size: 14px;
                margin-top: -1px;
                &:hover,&:active,&:focus{
                }
            }
        }
        a{
            display: block;
            width: 100%;
        }
    }
    .data-item-proyectos
    {
        padding:15px 15px 20px 15px;
        min-height: 217px;
        position:relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3{
            margin-bottom: 7px;
        }
        p{
            margin-bottom: 7px;
            line-height: 20px;
        }
        .reunion{   
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 0px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 15px;
                line-height: 18px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 16px;
                line-height: 20px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 16px;
                line-height: 20px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 17px;
                line-height: 21px;
            }
        }
        .content-button{
            padding-top: 20px;
            text-align: center;
            width: 100%;
        }
        @media only screen and (max-width: 575px) 
        {
            padding:13px 13px 16px 13px;
            min-height: inherit;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding:13.5px 13.5px 17px 13.5px;
            min-height: inherit;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding:14px 14px 18px 14px;
            min-height: inherit;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding:14.5px 14.5px 19px 14.5px;
            min-height: inherit;
        }
    }

    .data-item-busqueda{
        background-color:inherit;
        padding:15px 0px 20px 0px;
        min-height: 80px;
        @media only screen and (max-width: 575px) 
        {
            padding:11px 11px 14px 11px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding:11.5px 11.5px 14.5px 11.5px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding:12px 12px 15px 12px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding:12.5px 12.5px 15.5px 12.5px;
        }
    }
}

.col-proyectos{
    //margin-bottom: 35px;

    @media only screen and (max-width: 575px) 
    {
        //margin-bottom: 24px;
        &:nth-child(1n+1){
            clear: both;
            padding: 0px 5px;
        }
        width: 50%;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        margin-bottom: 26px;
        &:nth-child(2n+1){
            clear: both;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        margin-bottom: 28px;
        &:nth-child(2n+1){
            clear: both;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        margin-bottom: 30px;
        &:nth-child(3n+1){
            clear: both;
        }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) 
    {
        &:nth-child(3n+1){
            clear: both;
        }
    }
    @media (min-width: 1600px)
    {
        &:nth-child(4n+1){
            clear: both;
        }
    }
}
/*/Proyectos*/
/*componente ACTIVIDADES*/

/*componente MODELFIELDSELECT*/     //ok
.container-resumen-ods {
    display: -webkit-box;
    margin-bottom: 10px;
    .resumen-section {
        width:66%;
        padding-right:15px;
        @media only screen and (max-width: 575px) 
        {
            padding-right:0px;
        }
    }
    .ods-section {
        width:45%;
        p{
            margin-bottom: 10px;
        }
        .ant-select-selector
        {
            .ant-select-selection-item
            {
            }
            .ant-select-selection-item-content
            {
            }
        }
        @media only screen and (max-width: 575px) 
        {
            margin-top: 15px;
            width:100%;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            width:100%;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            width:70%;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            width:60%;
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) 
        { 
            width:55%;
        }
    }
    @media only screen and (max-width: 575px) 
    {
        display: block;
        .resumen-section, .ods-section {
            width: 100%;
        }
    }
}
/*/componente MODELFIELDSELECT*/

/*componente COMENTARIOS*/  //ok
.content-comentarios
{
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    .bg-comentarios
    {
        min-height: 100px;
        padding: 16px 15px;
        flex:1;   
        margin-bottom: 0px;
        h4{
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 5px;
        } 
        .img-user-profile-comment{
            border-radius: 50%;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            margin-top: -7px;
            @media only screen and (max-width: 575px) 
            {
                width: 35px;
                height: 35px;
            }
        }    
        .element-edit
        {
            margin-bottom: 15px;
            div
            {
                height: 100%;
                resize: none;
            }
            p
            {
                text-align: left;
                white-space: break-spaces;
                margin-bottom: 16px;
            }
        }
        .title-edit-comentarios{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;
            padding-bottom: 10px;
            h3{
                font-size: 20px;
                @media only screen and (max-width: 575px) 
                {
                    font-size: 16px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    font-size: 17px;
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                    font-size: 18px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                    font-size: 19px;
                }
            }
            @media only screen and (max-width: 575px) 
            {
                margin-bottom: 16px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-bottom: 17px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                margin-bottom: 18px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                margin-bottom: 19px;
            }
        }
    }
}

.content-comentarios-agregar
{
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    .bg-comentarios-agregar
    {
        min-height: 300px;
        flex:1; 
        padding: 16px 15px; 
        margin-bottom: 0px;
        .element-edit{  
            margin-bottom: 15px;
            height: 100%;
            div
            {
                height: unset;
                resize: none;
            }
            p, .ant-typography {
                text-align: left;
                white-space: break-spaces;
            }
            .ql-editor, .ql-toolbar, .ql-container {
                height: unset;
            }
            .quill {
                margin-bottom: 15px;
            }
            img{
                max-width: 100%;
                height: auto;
            }
        }
        .ql-toolbar, .ql-container{
        }
        .ck-editor__editable{
            min-height: 200px;
        }
    }
}

/*componente COMENTARIOS*/  //ok

/*componente ARCHIVOS COMPLEMENTARIOS*/  //ok
.content-archivos-complementarios
{
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    .bg-files
    {
        height: auto;
        flex:1;
        padding: 16px 15px;
        .element-edit
        {
            margin-bottom: 15px;
            height: 100%;
            div{
                height: unset;
                text-align: left;
                resize: none;
            }    
        }
    }
    .listado-recursos-complementarios
    {
        padding: 13px 15px;
        .ant-list-items
        {
            padding:0px 0px;
            margin:0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media only screen and (max-width: 575px) 
            {
                display: block;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                display: block;
            }
            & > li.ant-list-item{
                background-image: inherit;
                padding:0px;
                width: 50%;
                margin-bottom: 25px;
                border-bottom: 0px;
                &::before{
                    content:none;
                }
                @media only screen and (max-width: 575px) 
                {
                    width: 100%;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    width: 100%;
                }
                .ant-avatar-image{
                    border-radius: 0px;
                    @media only screen and (max-width: 575px) 
                    {
                        width: 24px !important;
                        height: auto !important;
                    }
                    @media only screen and (min-width: 576px) and (max-width: 767px) 
                    {
                        
                    }
                    @media only screen and (min-width: 768px) and (max-width: 991px) 
                    {
                    }
                    @media only screen and (min-width: 992px) and (max-width: 1199px) 
                    {
                    }
                }
                h4.ant-list-item-meta-title{
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 0px;
                    
                    line-height: 22px;
        
                    @media only screen and (max-width: 575px) 
                    {
                        font-size: 14px;
                        line-height: 18px;
                    }
                    @media only screen and (min-width: 576px) and (max-width: 767px) 
                    {
                        font-size: 15px;
                        line-height: 19px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 991px) 
                    {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    @media only screen and (min-width: 992px) and (max-width: 1199px) 
                    {
                        font-size: 17px;
                        line-height: 21px;
                    }
                }
                a{
                    
                    line-height: 19px;
                }
                .ant-list-item-meta-avatar{
                    @media only screen and (max-width: 575px) 
                    {
                        margin-right: 11px;
                    }
                }
            }
        }
        .cls-1{
        }
        .ant-list
        {
            a{
            }
            svg{
                path{
                    opacity:1;
                }
            }
        }
        @media only screen and (max-width: 575px) 
        {
            padding: 13px 0px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding: 13px 0px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
        }
    }
}
/*/componente ARCHIVOS COMPLEMENTARIOS*/ 

/*componente MODELFIELDSIMPLETEXT*/  //ok
.content-text
{
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    .bg-text
    {
        min-height: 60px;
        flex:1;
        padding:16px 15px;
        .element-edit{  
            display: flex;
            height: 100%;
            p, .ant-typography {
                text-align: left;
                white-space: break-spaces;
            }
            .ql-editor, .ql-toolbar, .ql-container {
                height: unset;
            }
            .quill {
                margin-bottom: 15px;
            }
            img{
                max-width: 100%;
                height: auto;
            }

            div:first-child 
            {
                width: 100%;
                margin-right: 10px;
            }
            a {
                float: right;
            }
            p {
                margin-bottom: 0px;
            }

            textarea, div {
                height: unset;
            }
        }
    }
}
/*/componente MODELFIELDSIMPLETEXT*/ 

/*componente MODELFIELDEDITOR*/  //ok
.content-editor
{
    display: flex;
    margin-bottom: 20px;
    .bg-editor
    {
        flex:1;
        min-height: 180px;
        padding: 16px 15px;
        max-width: 100%;
        .element-edit
        {
            height: 100%;
            margin-bottom: 15px;
            div, textarea {
                height: 100%;
                resize: none;
            }

            p, .ant-typography {
                text-align: left;
                white-space: break-spaces;
            }
            div, textarea {
                height: 100%;
                resize: none;
            }
            .ql-editor, .ql-toolbar, .ql-container {
                height: unset;
            }
            .quill {
                margin-bottom: 15px;
            }
            img{
                max-width: 100%;
                height: auto;
            }
        }
    }
    p{
        margin-bottom: 16px;
    }
    @media only screen and (max-width: 575px) {
        .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
            height: auto;
        }
        .ck.ck-toolbar>.ck-toolbar__items>:not(.ck-toolbar__line-break), .ck.ck-toolbar>.ck.ck-toolbar__grouped-dropdown {
            margin-bottom: 0px;
            margin-top: 0px;
        }
        .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
            padding-left: 0px;
        }
        .ck.ck-list__item .ck-button {
            width: 58%;
        }
    }
}
/*componente MODELFIELDEDITOR*/

/* Facilitadores */  //ok
.item-fac
{
    display: flex;
    margin-bottom: 60px;
    .img-item-fac{
        margin-right: 4%;
        img{
            max-width: 87px;
            max-height: 87px;
            border-radius: 50%;
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                max-width: 80px;
                max-height: 82px;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1599px) 
            {
                max-width: 80px;
                max-height: 80px;
            }
            @media only screen and (max-width: 575px) 
            {
                width: 80px;
                height: 80px;
            }
        }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) 
        {
            margin-right: 2%;
        }
    }
    .info-item-fac{
        h3{
            margin-bottom: 1px;
        }
        span{
            
        }
        .content-buttons{
            padding-top:7px;
            a{
                &:first-child{
                    @media only screen and (max-width: 575px) 
                    {
                        margin-bottom: 10px;
                    }
                    @media only screen and (min-width: 576px) and (max-width: 767px) 
                    {
                        margin-bottom: 10px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 991px) 
                    {
                        margin-bottom: 10px;
                    }
                    @media only screen and (min-width: 992px) and (max-width: 1199px) 
                    {
                        margin-bottom: 10px;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1599px) 
                    {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 575px) 
    {
        margin-bottom: 30px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        margin-bottom: 40px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        margin-bottom: 50px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        margin-bottom: 55px;
    }
}
.col-item-fac{
    @media only screen and (max-width: 575px) 
    {
        &:nth-child(1n+1){
            clear: both;
        }
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        &:nth-child(2n+1){
            clear: both;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        &:nth-child(2n+1){
            clear: both;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        &:nth-child(3n+1){
            clear: both;
        }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) 
    {
        &:nth-child(3n+1){
            clear: both;
        }
    }
    @media (min-width: 1600px)
    {
        &:nth-child(3n+1){
            clear: both;
        }
    }
}
/* /Facilitadores */

/* Unidades*/  //ok
.date-clock{
    display: flex;
    margin-bottom: 13px;
    .img-clock{
        width: 25px;
        padding-top:3px;
        svg{
            path{
            }
        }
    }
}
.info-video-lect{
    display: flex;
    align-items: center;
    @media only screen and (max-width: 575px) 
    {
        flex-direction: column;
        align-items: flex-start;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        flex-wrap: wrap;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        flex-wrap: wrap;
    }
    span{
        display: flex;
        align-items: center;
        img{
            position: relative;
            top:-1px;
            margin-right: 10px;
        }
        svg{
            position: relative;
            top:2px;
            margin-right: 8px;
            path{
            }
        }

        &::before{
            content:'\00b7';
            font-size: 35px; 
            line-height: 25px; 
            margin-right: 5px;
            margin-left: 7px;
            display: inline-block; 
            vertical-align: middle;
            @media only screen and (max-width: 575px) 
            {
                margin-left: 0px;
            }
        }
        &:first-child{
            &::before{
                content:'';
                margin: 0px;
            }
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            width: 50%;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            width: 50%;
        }
    }
}

.item-unidades
{
    display: flex;
    flex-wrap: nowrap;
    padding-bottom:35px;
    .week-item{
        flex: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 575px) 
        {
            align-items: flex-start;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            align-items: flex-start;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            align-items: flex-start;
        }
        p{
            font-size: 24px;
            line-height: 26px;
            text-align: center;
            span{
                display: block;
            }
            @media only screen and (max-width: 575px) 
            {
                font-size: 16px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 18px;
            }
        }

        .linea {
            width: 2px;
            height: 116%;
            transform: translateY(50%);
            position: relative;
            margin-left:34px;
            @media only screen and (max-width: 575px) 
            {
                width: 1px;
                margin-left:15px;
                transform: translateY(1%);
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-left:34px;
                width: 1px;
                transform: translateY(1%);
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                margin-left:34px;
                width: 1px;
                transform: translateY(1%);
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                margin-left:34px;
                width: 1px;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1599px) 
            {
                margin-left:34px;
                width: 1px;
            }

            &::after{
                content:'\00b7';
                font-size: 90px; 
                display: inline-block; 
                vertical-align: top;
                position: absolute;
                left:-11px;
                top:-12px;
            }
        }
    }
    .image-item{
        flex:7;
        text-align: left;
        padding-left: 50px;
        margin-right:20px;
        @media only screen and (max-width: 575px) 
        {
            padding-left: 15px;
            margin-right:14px;
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
    .data-item{
        flex:21;
    }

    &:last-child{
        .week-item{
            .linea {
                height: 0%;
            }
        }
    }
}
/* /Unidades */

/* Cursos */  //ok
.background-cursos
{
    /*background-image: url(${bg_programa});*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center center;
    min-height: 360px;
    padding: 60px 40px;

    @media only screen and (max-width: 575px) 
    {
        padding: 25px 15px;         
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding: 40px 20px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        padding: 55px 35px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        padding: 57px 37px;
    }

    .data-cursos{
        display: flex;
        flex-wrap: nowrap;
        .image-plei{
            flex:7;
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                flex: 10;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                flex: 10;
            }
        }
        .data-text-cursos{
            flex:18;
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                flex:15;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                flex: 16;
            }
        }
        @media only screen and (max-width: 575px) 
        {
            display: block; 
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            display: block; 
        }
    }

    .image-plei{
        padding-top: 10px;
        padding-right: 35px;
        img{
            max-width: 90%;
            height: auto;
            @media only screen and (max-width: 575px) 
            {
                max-width: 85%;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                max-width: 90%;
            }
        }
        @media only screen and (max-width: 575px) 
        {
            text-align: center;  
            padding-right: 0px;
            margin-bottom: 12px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding-right: 0px;
            margin-bottom: 15px;
            text-align: center; 
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding-right: 31px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding-right: 33px;
        }
    }

    .data-title{
        display: flex;
        justify-content: space-between;
        padding-bottom: 17px;
        margin-bottom: 18px;
        @media only screen and (max-width: 575px) 
        {
            padding-bottom: 10px;
            margin-bottom: 13px;
            display: block;
            text-align: center;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding-bottom: 11px;
            margin-bottom: 14px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding-bottom: 13px;
            margin-bottom: 14px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding-bottom: 15px;
            margin-bottom: 16px;
        }

        .p-title{
            font-size: 44px;
            line-height: 52px;
            font-weight: bold;
            margin-bottom: 12px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 30px;
                line-height: 38px;
                margin-bottom: 10px;
                text-align: center;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 33px;
                line-height: 42px;
                margin-bottom: 9px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 10px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 42px;
                line-height: 50px;
                margin-bottom: 10px;
            }
        }

        .ul-descripcion{
            display: flex;
            padding: 0px;
            margin: 0px;
            li{
                background-image: inherit;
                padding:0px;
                margin: 0px;
                font-size: 20px;
                position: relative;
                padding-left:12px;
                margin-right:24px;
                line-height: 24px;
                &::after{
                    content:'\00b7';
                    font-size: 40px; 
                    display: inline-block; 
                    vertical-align: middle;
                    position: absolute;
                    left:-11px;
                    top:2px;
                }
                &:first-child{
                    padding-left: 0px;
                    &::after{
                        content:'';
                        display: none;
                        left: 0px;        
                    }
                }
                @media only screen and (max-width: 575px) 
                {
                    font-size: 16px;
                    position: relative;
                    padding-left:7px;
                    margin-right:16px;
                    margin-bottom: 10px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    font-size: 17px;
                    position: relative;
                    padding-left:8px;
                    margin-right:18px;
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                    font-size: 18px;
                    position: relative;
                    padding-left:9px;
                    margin-right:20px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                    font-size: 19px;
                    position: relative;
                    padding-left:11px;
                    margin-right:22px;
                }
            }
            @media only screen and (max-width: 575px) 
            {
                justify-content: center;
            }
        }
        .content-button{
            display: flex;
            align-items: flex-end;
            @media only screen and (max-width: 575px) 
            {
                display: block;
                text-align: center;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                min-width: 150px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                min-width: 160px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                min-width: 160px;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1599px) 
            {
                min-width: 180px;
            }
        }
    }

    .data-descripcion{
        p{
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 14px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 15px;
                line-height: 19px;
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 17px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 17px;
                line-height: 22px;
                margin-bottom: 18px;
            }
        }
        .logo-melian{
            display: flex;
            align-items: center;
            img{
                margin-right: 5px;
            }
            span{
                font-weight: 500;
                font-size: 27px;
                line-height: 33px;
                display: inline-block;
                @media only screen and (max-width: 575px) 
                {
                    font-size: 19px;
                    line-height: 24px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    font-size: 21px;
                    line-height: 26px;
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                    font-size: 23px;
                    line-height: 28px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                    font-size: 25px;
                    line-height: 30px;
                }
            }
        }
    }
}

.content-cursos{
    padding:15px 43px 35px 38px;
    small{
    }
    @media only screen and (max-width: 575px) 
    {
        padding:11px 8px 10px 8px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding:12px 37px 0px 32px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        padding:13px 39px 0px 34px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        padding:14px 41px 0px 36px;
    }

    .ant-tabs{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @media only screen and (max-width: 575px) 
        {
            font-size: 14px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 14.5px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 15px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 15.5px;
        }
        p{
            margin-bottom: 16px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 14px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 14.5px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 15px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 15.5px;
            }
        }
        ul{
            margin-bottom: 15px;
        }
        h3{
            a{
                &:hover,&:active,&:focus
                {
                }
            }
            @media only screen and (max-width: 575px) 
            {
                margin-bottom: 13px !important;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-bottom: 15px !important;
            }
        }
    }

    .ant-tabs-top > .ant-tabs-nav
    {
        margin-bottom: 40px;
        &::before
        {
        }
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 20px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            margin-bottom: 20px;
        }
    }

    .ant-tabs-nav-container{
        font-size: 26px;
        line-height: 31px;
        @media only screen and (max-width: 575px) 
        {
            font-size: 16px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 18px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 24px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 25px;
        }
    }

    .ant-tabs-nav .ant-tabs-tab
    {
        font-weight: 600;
        padding:20px 16px;
        font-size: 26px;
        &:hover, &:active, &:focus
        {
            .ant-tabs-tab-btn{
            }
        }
        @media only screen and (max-width: 575px) 
        {
            padding:13px 10px;
            font-size: 19px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            padding:16px 13px;
            font-size: 22px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding:18px 14px;
            font-size: 23px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding:19px 15px;
            font-size: 24px;
        }
    }

    .ant-tabs-nav .ant-tabs-tab-active{
        position: relative;
        &::after{
            content:' ';
            display: block;
            width: 60%;
            height: 7px;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            bottom:-3px;
            left:50%;
            z-index: 100;
            transform: translateX(-50%);
        }
        .ant-tabs-tab-btn{
        }
        &:hover, &:active, &:focus
        {
            .ant-tabs-tab-btn
            {
            }
        }
    }
    .ant-tabs-tab-btn{
    }
    .ant-tabs-bar
    {
        margin-bottom: 40px;
        @media only screen and (max-width: 575px) 
        {
            margin-bottom: 15px;
        }
    }
    .ant-tabs-ink-bar{
        display: none !important;
    }
}

.logos-apoyo
{
    display: flex;
    flex-wrap: wrap;
    
}
.logo-a
    {
        text-align: center;
        //width: 32%;
        display: flex;
        align-items: center;
        justify-content: center;
        //margin-bottom: 20px;
        img{
            max-width: 100%;
            @media (min-width: 1600px)
            {
                //width: 100%;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1599px) 
            {
                //width: 100%;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                //width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                //width: 100%;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                max-width: 70%;
            }
            @media only screen and (max-width: 575px) 
            {
                max-width: 60%;
            }
        }
        
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            width: 100%;
        }
    }
/* CURSOS */ 

/* componente PROGRESOCURSO*/  //ok
.bg-progreso-curso
{
    padding:16px 0px 0px 0px;
    margin-bottom:35px; 
    .number-title
    {
        display:flex;
        margin-bottom: 0px;
        span{
            display:inline-block;
            font-weight: 600;
            font-size: 26px;
            line-height: 40px;
            margin-right: 18px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 28px;
                line-height: 30px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 32px;
                line-height: 32px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 28px;
                line-height: 34px;
                margin-right: 8px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 40px;
                line-height: 36px;
            }
        }
        p{
            line-height: 20px;
            margin-bottom: 0px;
        }
    }
    
    .progress-title
    {
        padding:0px 15px;
        font-size: 24px;    
        @media only screen and (max-width: 575px) 
        {
            font-size: 20px;
        }
    }

    .progress-title-button{
        padding:0px 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;
        .progress-title{
            padding:0px;
            margin-bottom: 0px;
        }
    }

    .ant-divider
    {
    }
    .row-progress-title
    {
        padding:0px 15px;
        margin-bottom: 3px;
    }
    .ant-collapse{
        border:none;
    }
    .ant-collapse-header
    {
        padding-top:10px;
        h3{
            font-weight: 400; 
            
        }
        span.ant-collapse-arrow{
            font-size: 30px !important;
            padding-top: 8px;
        }
    }
    .ant-collapse-content
    {
        border-top:none;
    }

    .ant-collapse-item{
        border-bottom: none !important;   
    }

    .ant-slider.progress-profile{
        margin:20px 0px 0px 0px;
        .ant-slider-rail{
        }
        &:hover{
            .ant-slider-rail{
            }
        }
        .ant-slider-track{ 
        }
        .ant-slider-handle{
            display: none;
        }
    }
    .ant-btn{
        border-width:2px;
    }
    .button-progress-entregado {
        border-color: dodgerblue;
        opacity: 1;
        cursor: not-allowed;
        //pointer-events: none;
        &:hover,&:active,&:focus{
            border-color: dodgerblue;
            background-color: transparent;
            color:dodgerblue;
        }
    }
    .button-progress-pendiente {
    }
    .button-progress-aprobado {
        cursor: default;
        border-color: green;
        background-color: transparent;
        color:#fff;
        &:hover,&:active,&:focus{
            border-color: green;
            background-color: transparent;
            color:#fff;
        }
    }
    .button-progress-revision {
        cursor: default;
        border-color: orange;
        background-color: transparent;
        color:#fff;
        &:hover,&:active,&:focus{
            border-color: orange;
            background-color: transparent;
            color:#fff;
        }
    }
    .ant-collapse-header h3 {
        margin-bottom: 0px;
        font-weight: 500;
    }

    .detalle-actividad 
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            font-size: 16px;
            margin-bottom: 0px;
        }
    }
}
/* /componente PROGRESOCURSO*/

/* componente PROGRESO */  //ok
.bg-progreso
{
    padding: 22px 25px 22px 25px;
    margin-bottom:35px;
    .number-title
    {
        display:flex;
        margin-bottom: 15px;
        span{
            display:inline-block;
            font-weight: 600;
            font-size: 38px;
            line-height: 40px;
            margin-right: 18px;
            @media only screen and (max-width: 575px) 
            {
                font-size: 28px;
                line-height: 30px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                font-size: 32px;
                line-height: 32px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                font-size: 28px;
                line-height: 34px;
                margin-right: 8px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                font-size: 40px;
                line-height: 36px;
            }
        }
        p{
            font-size:14px;
            margin-bottom:0px;
            line-height: 20px;
        }
    }
    
    .ant-slider.progress-profile{
        margin:20px 0px 0px 0px;
        .ant-slider-rail{
        }
        &:hover{
            .ant-slider-rail{
            }
        }
        .ant-slider-track{ 
        }
        .ant-slider-handle{
            display: none;
        }
    }
}
/* /componente PROGRESO */


/*faq */    //OK
.faq-accordion{
    .ant-collapse{
        background-color: transparent;
        border:none;
        & > .ant-collapse-item{
            margin-bottom: 40px;
            & > .ant-collapse-header{
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
                padding:0px 0px 41px 40px;
                .ant-collapse-arrow {
                    left:14px;
                    top:5px;
                    
                    transform: translateY(-50%);
                }
                @media only screen and (max-width: 575px) 
                {
                    font-size: 15px;
                    line-height: 18px;
                    padding:0px 0px 25px 30px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    font-size: 16px;
                    line-height: 19px;
                    padding:0px 0px 25px 33px;
                }
                @media only screen and (min-width: 768px) and (max-width: 991px) 
                {
                    font-size: 17px;
                    line-height: 20px;
                    padding:0px 0px 37px 36px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) 
                {
                    font-size: 17.5px;
                    line-height: 21px;
                    padding:0px 0px 39px 38px;
                }
            }
            @media only screen and (max-width: 575px) 
            {
                margin-bottom: 30px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                margin-bottom: 30px;
            }
        }
    }

    .ant-collapse-content{
        background-color: transparent;
        font-size: 16px;
        line-height: 24px;
        border-top: 0px;
        & > .ant-collapse-content-box{
            padding:0px 10px 35px 10px;
            @media only screen and (max-width: 575px) 
            {
                padding:0px 7px 27px 7px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                padding:0px 7px 29px 7px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                padding:0px 8px 31px 8px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                padding:0px 9px 33px 9px;
            }
        }
        @media only screen and (max-width: 575px) 
        {
            font-size: 14px;
            line-height: 20px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 14.5px;
            line-height: 21px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 15px;
            line-height: 22px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 15.5px;
            line-height: 23px;
        }
    }
}
/* /faq */

/*Calendario*/    //ok
.content-calendar
{
    @media only screen and (max-width: 575px)
    {
        font-size: 14px;
    }
}

.item-calendar
{
    margin-bottom: 25px;
    &:last-child
    {
        margin-bottom: 0px;
    }
    .month
    {
        display: flex;
        margin-bottom: 10px;
        div
        {
            transform: translateX(-7px);    
        }
        span
        {
            margin-left: 12px;
            text-transform: uppercase;
            @media only screen and (max-width: 575px)
            {
                margin-left: 2px;    
            }
        }
    }
    .data-item-calendar
    {
        padding-left: 26px;
        padding-bottom: 6px;
        padding-top: 4px;
        .event-item
        {
            display: flex;
            flex-wrap: nowrap;
            min-height: 80px;
            margin-bottom: 10px;
            &:last-child
            {
                margin-bottom: 0px;
            }
            .day-event
            {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-right: 27px;
                min-width: 80px;
                span
                {
                    display: inline-block;
                    white-space: nowrap;
                    @media only screen and (max-width: 575px)
                    {
                    }
                }
                @media only screen and (max-width: 575px)
                {
                    padding-right: 14px;
                    min-width: 59px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    padding-right: 5px;
                    min-width: 70px;
                }
            }
            .info-event
            {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding-left: 2%;
                padding-right: 2%;
                @media only screen and (max-width: 575px) 
                {
                    display: block;
                    padding-top: 7px;
                    padding-bottom: 7px;  
                    padding-left: 7px;
                    padding-right: 7px;      
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    display: block;
                    padding-top: 10px;
                    padding-bottom: 10px;  
                    padding-left: 15px;
                    padding-right: 15px;
                }   
                .hours-details-event
                {   
                    display: flex;
                    .hours
                    {
                        display: flex;
                        align-items: center;
                        margin-right: 75px;
                        min-width: 92px;
                        @media only screen and (min-width: 576px) and (max-width: 767px) 
                        {
                            margin-right: 25px;
                        }
                        @media only screen and (min-width: 768px) and (max-width: 991px) 
                        {
                            margin-right: 25px;
                        }
                        @media only screen and (max-width: 575px) 
                        {
                            margin-right: 0px;
                            align-items: flex-start;
                            min-width: 95px;
                        }
                        span
                        {
                            display: inline-block;
                            white-space: nowrap;
                            @media only screen and (max-width: 575px) 
                            {
                                padding-top: 1px;    
                            }
                        }
                    }
                    .details-event
                    {
                        display: flex;
                        align-items: center;
                        margin-right: 75px;
                        position:relative;
                        @media only screen and (min-width: 576px) and (max-width: 767px) 
                        {
                            margin-right: 35px;
                        }
                        @media only screen and (min-width: 768px) and (max-width: 991px)
                        {
                            margin-right: 65px;
                        }
                        @media only screen and (max-width: 575px) 
                        {
                            margin-right: 5px;
                            align-items: flex-start;
                        }
                        .span-details-event
                        {
                            display: inline-block;
                            white-space: nowrap;
                            @media only screen and (max-width: 575px)
                            {
                                white-space: inherit;
                            }
                            @media only screen and (min-width: 576px) and (max-width: 767px)
                            {
                                white-space: inherit;
                            }
                        }
                        .span-notification
                        {
                            position: absolute;
                            top:4px;
                            right:-40%;
                            @media only screen and (min-width: 768px) and (max-width: 991px)
                            {
                                right: -33%;
                            }
                            @media only screen and (min-width: 576px) and (max-width: 767px)
                            {
                                position: relative;
                                top:5px;
                                right:0px;
                            }
                            @media only screen and (max-width: 575px)
                            {
                                position: relative;
                                top:5px;
                                right:0px;
                            }
                        }
                    }
                    @media only screen and (max-width: 575px) 
                    {
                        justify-content: space-between;
                        text-align: right;
                        margin-bottom: 12px;   
                    }
                    @media only screen and (min-width: 576px) and (max-width: 767px) 
                    {
                        justify-content: space-between;
                        text-align: right;
                        margin-bottom: 12px;
                    }
                }
                .modulo-event
                {
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: 575px) 
                    {
                        align-items: flex-start;   
                    }
                    .span
                    {
                        display: inline-block;
                    }
                    .bullet-square
                    {
                        margin-right:12px;
                        @media only screen and (max-width: 575px) 
                        {
                            position: relative;
                            margin-right: 7px;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 575px) 
        {
            padding-left: 10px;    
        }
    }
}

.span-notification
{
    display: inline-block;
    line-height: 1.3em;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    @media only screen and (max-width: 575px)
    {
        font-size: 12px;
        padding-left: 3px;
        padding-right: 3px;    
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        font-size: 12px;
        padding-left: 3px;
        padding-right: 3px;    
    }
}

.bullet-square
{
    width: 10px;
    height: 10px;
    display: inline-block;
    @media only screen and (max-width: 575px) 
    {
        width: 8px;
        height: 8px;   
    }
}

.span-title-modulo{
}
/* /Calendario*/


/* Video */  //ok
.no-display{
    display: none;
}

.header-full{
    .ant-layout-header.isomorphicTopbar
    {
        padding-left:4%;
    }
}

.page-video{
    padding-left: 0px;
    padding-right: 0px;
    padding-top:35px;
    padding-bottom:0px;
}

.content-video{
    display: flex;
    flex-wrap: nowrap;
    .video{
        flex:21;
        img{
            max-width: 100%;
            height: auto;
        }
        @media only screen and (max-width: 575px)
        {
            flex:inherit;
            width: 100%;
            margin-bottom: 15px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
            flex:inherit;
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .info-video{
        flex:5;
        @media only screen and (max-width: 575px)
        {
            flex:inherit;
            width: 100%;
            margin-bottom: 40px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
            flex:inherit;
            width: 100%;
            margin-bottom: 40px;
        }
    }
    @media only screen and (max-width: 575px)
    {
        flex-wrap: wrap;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px)
    {
        flex-wrap: wrap;
    }
}

.info-video{
    .info-description{
        position: relative;
        .link-close{
            position: absolute;
            top:15px;
            right:15px;
        }
    }
}

.tabs-video{
    .ant-tabs{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        p{
            margin-bottom: 16px;
            @media only screen and (max-width: 575px)
            {
                margin-bottom: 12px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px)
            {
                margin-bottom: 13px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                margin-bottom: 14px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                margin-bottom: 15px;
            }
        }
        ul{
            margin-bottom: 15px;
            li{
                margin-bottom: 5px;
            }
            @media only screen and (max-width: 575px)
            {
                margin-bottom: 11px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px)
            {
                margin-bottom: 12px;
            }
            @media only screen and (min-width: 768px) and (max-width: 991px) 
            {
                margin-bottom: 13px;
            }
            @media only screen and (min-width: 992px) and (max-width: 1199px) 
            {
                margin-bottom: 14px;
            }
        }
        @media only screen and (max-width: 575px)
        {
            font-size: 15px;
            line-height: 20px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
            font-size: 15.5px;
            line-height: 21px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 15px;
            line-height: 22px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 15.5px;
            line-height: 23px;
        }
    }

    .ant-tabs-nav-container{
        font-size: 14px;
        line-height: 31px;
    }

    .ant-tabs-nav .ant-tabs-tab{
        font-weight: 600;
        padding:13px 16px;
        @media only screen and (max-width: 575px)
        {
            padding:10px 12px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
            padding:11px 13px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding:12px 14px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding:12px 15px;
        }
    }
    
    .ant-tabs-nav .ant-tabs-tab-active{
        position: relative;
        &::after{
            content:' ';
            display: block;
            width: 60%;
            height: 4px;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            bottom:0px;
            left:50%;
            z-index: 100;
            transform: translateX(-50%);
        }
    }
    .ant-tabs-nav .ant-tabs-tab:hover{
    }
    .ant-tabs-bar{
        margin-bottom: 0px;
    }
    .ant-tabs-ink-bar{
        display: none !important;
    }
    .data-tabs-video{
    }
}

.accordion-video{
    .ant-collapse{
        background-color: transparent;
        border:none;
        font-size: 16px;
        line-height: 22px;
        @media only screen and (max-width: 575px)
        {
            font-size: 14px;
            line-height: 18px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
            font-size: 14.5px;
            line-height: 19px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 15px;
            line-height: 20px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            font-size: 15.5px;
            line-height: 21px;
        }    
    }
    .ant-collapse > .ant-collapse-item{
        border-bottom: none;
        margin-bottom: 10px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        font-weight: 600;
        line-height: 21px;
        font-size: 18px;
        padding:25px 16px 21px 22px;
        position: relative;
        border-bottom: 1px solid transparent;
        .info-header{
            font-weight: 600;
            line-height: 21px;
            font-size: 18px;
            @media only screen and (max-width: 575px)
            {
                font-size: 16px;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px)
            {
                font-size: 17px;
            }
        }
        @media only screen and (max-width: 575px)
        {
            font-size: 15px;
            line-height: 18px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
            font-size: 16px;
            line-height: 19px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            font-size: 16.5px;
            line-height: 19px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            line-height: 20px;
            font-size: 17px;
        }
    }

    .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header{
    }

    .ant-collapse-content > .ant-collapse-content-box{
        padding:22px 16px 16px 48px;
        @media only screen and (max-width: 575px)
        {
            padding:14px 11px 11px 36px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
            padding:16px 13px 13px 40px;
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) 
        {
            padding:18px 14px 14px 43px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) 
        {
            padding:20px 15px 15px 45px;
        }
    }
    .ant-collapse-item:last-child > .ant-collapse-content{
        border-radius:0px 0px 0px 0px;
    }
    .ant-collapse-content{
        border-top:none;
        
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra{
        float: none;
    }
    .info-sesion{
        font-size: 14px;
        line-height: 32px;
        font-weight: 400;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        top:30px;
        transform: translateY(0px);
    }
}

.embed-container {
}
.embed-container {
    --video--width: 1296;
    --video--height: 740;
    position: relative;
    padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* /Video */

/* seccion inicio*/  //ok
.content-layout-course{
}

.topbar-courses{
    padding-right: 2%;
    padding-left: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    @media only screen and (max-width: 575px) 
    {
        padding-right: 0px;
        padding-left: 15px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding-right: 0px;
        padding-left: 0px;
    }
    h2
    {
        margin: 0px;
        @media only screen and (max-width: 575px) 
        {
            font-size: 19px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            font-size: 19px;
        }
    }
    .topbar-courser-user{
        .isoRight
        {
            display: flex;
            align-items: center;
            .isoUser
            {
                display: flex;
                align-items: center;
                margin-right: 20px;
                a{
                }
            }
        }
    }
}

.full-width-courses{
    padding-right: 2%;
    padding-left: 2%;
    @media only screen and (max-width: 575px) 
    {
        padding-right: 0px;
        padding-left: 0px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding-right: 0px;
        padding-left: 0px;
    }
    .info-courses
    {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        @media only screen and (max-width: 575px) 
        {
            display: block;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) 
        {
            display: block;
        }

        .logo-info-full
        {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 35px;
            @media only screen and (max-width: 575px) 
                {
                    margin-right:0px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    margin-right:0px;
                }
            .img-logo-info-full
            {  
                width: auto;      
                height: 100px;
                
                @media only screen and (max-width: 575px) 
                {
                    margin-bottom: 15px;
                    height: 90px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    margin-bottom: 15px;
                    height: 90px;
                }
            }
        }
        .text-info-full{
            flex:20;
            @media only screen and (max-width: 575px) 
            {
                padding-left: 15px;
                padding-right: 15px;
                text-align: center;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                padding-left: 15px;
                padding-right: 15px;
                text-align: center;
            }
        }
    }

    .details-courses{
        h4{
        margin-bottom: 20px;
        @media only screen and (max-width: 575px) 
            {
                text-align: center;
            }
            @media only screen and (min-width: 576px) and (max-width: 767px) 
            {
                text-align: center;
            }
        }
        .item-details-courses
        {
            background-repeat: no-repeat;
            background-size: cover;
            background-position:center top;
            min-height: 420px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 575px) 
                {
                    min-height: 370px;
                    margin-bottom: 20px;
                }
                @media only screen and (min-width: 576px) and (max-width: 767px) 
                {
                    min-height: 370px;
                    margin-bottom: 20px;
                }

            .data-item-details
            {
                min-width: 320px;
                .logo-item{
                    min-height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 30px;
                    .img-logo
                    {
                        max-height: 100px;
                        width: auto;
                    }
                }
                .info-item{
                    text-align: center;
                    .span-number{
                        display: inline-block;
                        font-size: 32px;
                        font-weight: 700;
                        margin-right: 5px;
                        line-height: 36px;
                    }
                }
                .button-item{
                    margin-top: 60px;
                    text-align: center;
                }

                .ant-slider.progress-courses{
                    margin:27px 0px 0px 0px;
                    .ant-slider-rail{
                        height: 5px;
                    }
                    &:hover{
                        .ant-slider-rail{
                        }
                    }
                    .ant-slider-track{
                        height: 5px;  
                    }
                    .ant-slider-step{
                        height: 5px;
                    }
                    .ant-slider-handle{
                        display: none;
                    }
                }  
            }
        }
    }
}

.back-color{
    padding-left: 1.8%;
    padding-right: 1.8%;
    padding-top: 30px;
    padding-bottom: 45px;
    @media only screen and (max-width: 575px) 
    {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom:30px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom:30px;
    }
}

.btn-courses{
  display: inline-block;
  font-weight: 500;
  padding: 5px 55px 6px 55px;
  &:hover,&:active,&:focus{
  }
}
/* /seccion inicio */

.image-description
{
    float:right;
    margin: 0em 0em 0em 3em;
    @media only screen and (max-width: 575px) 
    {
        float: none;
        max-width: 100%;
        height: auto;
        margin: 0em 0em 1em 0em;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
        float: right;
        margin: 0em 0em 1em 0.5em;
        max-width: 60%;
        height: auto;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) 
    {
        margin: 0em 0em 0em 2em;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) 
    {
        margin: 0em 0em 0em 2.5em;
    }
}


/*/Agregado */



/* Quiz */
body .ant-form-item-label {
  text-align: left;
}
body .ant-form-item, body .ant-form-item-label > label, body .ant-checkbox-wrapper,
body .ant-radio-disabled + span, body .ant-checkbox-disabled + span {
  color: white;
}
body .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #333;
}
body .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner:after{
  background-color: #333 !important;
}
body .ant-input[disabled] {
  color: #333;
}
.user-quiz-form .ant-btn.ant-btn-primary.button-main {
  padding: 6.5px 23px 5.5px;
  //border: 1px solid rgb(227, 75, 154);
  padding: 7.5px 20px 6.5px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  //color: rgb(255, 255, 255);
  //background-color: rgb(227, 75, 154);
  border-radius: 30px;
}
body .ant-form label.ant-radio-wrapper span.ant-radio-inner:after {
  top: 12px;
  left: 12px;
}
body .ant-form label.ant-radio-wrapper span.ant-radio:hover span.ant-radio-inner {
  border-color: white;
}
/* /Quiz */





